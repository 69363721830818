<script setup lang="ts">
import { useScroll } from '@vueuse/core'
const visible = useMenuOverlay()

const props = withDefaults(
	defineProps<{
		logo?: boolean
		hideMenuButton?: boolean
		position: 'absolute' | 'fixed'
	}>(),
	{
		logo: true,
		hideMenuButton: false,
		position: 'fixed',
	},
)

const windowEl = ref<Window>()
const { directions } = useScroll(windowEl)

onMounted(() => {
	windowEl.value = window
})

const isScrollingUp = ref(false)
watch(directions, (dir) => {
	if (props.position === 'absolute') return
	if (dir.top) isScrollingUp.value = true
	if (dir.bottom) isScrollingUp.value = false
})

const isLogoVisible = computed(() => {
	if (props.position === 'absolute') return true
	return useHeaderVisible().value ? true : isScrollingUp.value
})

const isHeaderVisible = useHeaderVisible()
const isLogoWhite = useLogoWhiteMode()
const isAPublication = useIsAPublication()
</script>

<template>
	<nav
		class="header-nav pointer-events-none w-full top-0 z-20 flex transition-all duration-1000 ease-in-out px-7 justify-between items-center h-[80px]"
		:class="[
			isAPublication ? 'pr-14' : 'pr-7',
			{
				fixed: position === 'fixed',
				absolute: position === 'absolute',
			},
		]"
		aria-label="Top navigatie"
	>
		<NuxtLink
			to="/"
			class="logo-link"
			:class="{
				visible: isLogoVisible,
			}"
			aria-label="NEMO Kennislink logo"
		>
			<Logo
				v-if="logo"
				class="logo"
				:white="
					useRoute().name === 'themas' ||
					useRoute().name === 'themas-slug' ||
					isHeaderVisible
						? isLogoWhite
						: false
				"
		/></NuxtLink>
		<button
			v-if="!hideMenuButton"
			class="menu-button"
			aria-controls="main-nav"
			:aria-expanded="visible"
			@click="visible = true"
		>
			menu
		</button>
	</nav>
</template>

<style scoped>
.logo-link {
	@apply transition ease-in-out duration-[400ms] -translate-y-20 opacity-0 pointer-events-auto;
}
.menu-button {
	@apply pointer-events-auto bg-nemo-green px-2 md:px-3 py-[2px] md:pt-[6px] md:pb-[4px] rounded-full font-semibold hover:scale-105 transition duration-200 hover:shadow-md;
}
.logo-link.visible {
	@apply translate-y-0 opacity-100;

	.hide-logo & {
		@apply translate-y-0 opacity-0;
	}
}
</style>
